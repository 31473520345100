"use client";
import { SeriesSubscriptionHeaderContent } from "@/components/SeriesSubscriptionHeaderContent";
import { SubscriptionHeaderContent } from "@/components/SubscriptionHeaderContent";
import React, { useState } from "react";
import { ContentData } from "@/cms/types";
import { SectionProps } from "../Section";
import { ProductVariantList } from "@/cms/types/objects";
import { PortableTextSection } from "@/portable-text/PortableTextSection";
import { truncatePortableText } from "@/portable-text/proseClamper";
import { BookContent } from "@/products/types";
import { getThemeClassName } from "@/theming/selectors";
import classNames from "classnames";
import { SubscriptionCampaignSection } from "./types";
import { Section } from "../types";
import { getCSSPropertiesTheme } from "@/theming/getCSSProperties";
import { getPageLink } from "@/navigation/selectors";
import { SubscriptionProduct } from "@cappelendamm/e-commerce-services";
import Image from "next/image";
import { isPrioritizedSection } from "../utils";
import { getImageAspectRatio, getImageProps } from "@/cms/images/getImageProps";
import Link from "next/link";
import { ValuePropositionListItems } from "@/portable-text/ValuePropositionList.tsx/ValuePropositionList";
import { SubscriptionImage } from "@/components/SubscriptionImage";
import { SanityImage } from "@/cms/images/types";

type SubscriptionCampaignFocusProps = ContentData<SubscriptionCampaignSection> &
  Omit<SectionProps, keyof Section> & {
    subscriptionProduct: SubscriptionProduct;
    firstThreeSanityImages: SanityImage[];
  };

const getSelectorItemsFromCampaignBooks = (
  books?: BookContent[],
): ProductVariantList => {
  const productVariants =
    books?.map((book, index) => {
      const subTitle = book.name;
      const title = `BOK ${(index += 1)}`;

      return {
        key: book.sku,
        title: title ?? "",
        subTitle: subTitle ?? "",
        image: book.image,
        slug: book.slug,
      };
    }) ?? [];
  return { items: productVariants };
};

export const SubscriptionCampaignFocus = ({
  data: {
    _id,
    subscription,
    description,
    price,
    valuePropositionList,
    theme,
    buttonTheme,
    ...content
  },
  subscriptionProduct,
  index,
  firstThreeSanityImages,
  universeTag,
}: SubscriptionCampaignFocusProps) => {
  const booksList = getSelectorItemsFromCampaignBooks(subscription?.books);
  const [startBookItem, setStartBookItem] = useState(booksList.items[0]);

  if (!subscription) return null;

  const link = getPageLink({
    pageType: "checkout",
    universeTag,
    params: subscriptionProduct.sku,
    searchParams: subscriptionProduct.isSeries
      ? { initialSku: startBookItem.key }
      : {},
  }).linkAs?.toString();

  const imageObject = content.image;
  return (
    <section
      className={classNames(
        getThemeClassName(content.styleVariant === "color" ? "primary" : null),
        "bg-body text-body-contrast relative overflow-hidden py-6 lg:py-12",
      )}
      style={{
        ...(content.styleVariant === "color"
          ? getCSSPropertiesTheme(theme)
          : {}),
      }}
    >
      <div className="app-grid relative mx-auto overflow-hidden px-3 lg:!overflow-visible lg:px-0">
        <div className="col-span-6 mb-6 flex flex-col sm:col-span-12">
          <h1 className="heading-01 leading-120">{subscriptionProduct.name}</h1>
          <span>{subscriptionProduct.plan.name}</span>
        </div>

        {(imageObject || firstThreeSanityImages) && (
          <div
            className={classNames(
              "relative col-span-full m-[auto] aspect-[4/3] w-full sm:col-span-3 md:col-start-1 md:col-end-7",
            )}
          >
            <Link href={link || ""}>
              {imageObject ? (
                <Image
                  {...getImageProps({
                    ...imageObject,
                    ...getImageAspectRatio({
                      height: 1140 / 2,
                      aspectRatio: "4/3",
                    }),
                  })}
                  fill
                  width={undefined}
                  height={undefined}
                  priority={isPrioritizedSection({ index })}
                  className="rounded object-cover"
                />
              ) : (
                <SubscriptionImage
                  parentElement="subscriptionCampaignFocus"
                  images={firstThreeSanityImages}
                />
              )}
            </Link>
          </div>
        )}
        <div
          className={classNames(
            "py-content sm:pr-content col-span-full sm:col-span-3 md:col-span-6 md:ml-12 lg:py-0",
          )}
        >
          <div>
            {description && (
              <div className="prose">
                <PortableTextSection
                  value={truncatePortableText(description, 10)}
                />
              </div>
            )}
            {subscriptionProduct?.isSeries ? (
              <SeriesSubscriptionHeaderContent
                price={price}
                buttonTheme={buttonTheme}
                styleVariant={content.styleVariant}
                subscription={subscriptionProduct}
                booksList={booksList}
                universeTag={universeTag}
                startBookItem={startBookItem}
                onChange={setStartBookItem}
              />
            ) : (
              <SubscriptionHeaderContent
                buttonTheme={buttonTheme}
                styleVariant={content.styleVariant}
                subscription={subscriptionProduct}
                universeTag={universeTag}
              />
            )}
            {valuePropositionList?.items ? (
              <ValuePropositionListItems
                className="flex-col !gap-3 pt-4"
                items={valuePropositionList.items}
              />
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubscriptionCampaignFocus;
